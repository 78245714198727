<template>
  <div class="field-of-application grid two-columns">
    <div class="grid-item">
      <InputRadio class="spacing" title="Plattenunterseite" :items="plateUndersideItems" />
      <InputRadio title="Einsatzbereich" :items="fieldOfApplicationItems" />
    </div>

    <div v-if="options.cantilever" class="grid-item">
      <InputRadio title="Oberseite" :items="plateUppersideItems" />
    </div>
  </div>
</template>

<script>
import InputRadio from "@/components/InputRadio";
import {mapGetters} from "vuex";
export default {
  name: "FieldOfApplication",
  components: {InputRadio},
  data() {
    return {
      plateUndersideItems: [
        { label: 'Innen (XC1)', option: 'plateUnderside', value: 'inside' },
        { label: 'Außen/Erdreich (XC2/XC3)', option: 'plateUnderside', value: 'outside' }
      ],
      plateUppersideItems: [
        { label: 'Innen (XC1)', option: 'plateUpperside', value: 'inside' },
        { label: 'Außen/Erdreich (XC2/XC3)', option: 'plateUpperside', value: 'outside' }
      ],
      fieldOfApplicationItems: [
        { label: 'Dach (nur Schneelast)', option: 'fieldOfApplication', value: 'roof' },
        { label: 'Dachterrasse', option: 'fieldOfApplication', value: 'roof terrace' },
        { label: 'Wohnung oder Büro', option: 'fieldOfApplication', value: 'apartment' },
        { label: 'Verkaufsraum oder Versammlungsstätte', option: 'fieldOfApplication', value: 'salesroom' },
        { label: 'Lagerraum', option: 'fieldOfApplication', value: 'storage room' }
      ]
    }
  },
  computed: {
    ...mapGetters(['options'])
  }
};
</script>

<style lang="scss">
.field-of-application {
  grid-column-gap: 32px;

  @media (max-width: $width-tablet) {
    grid-template-columns: 1fr !important;
    grid-row-gap: 24px;
  }

  .spacing {
    margin-bottom: 24px;
  }
}
</style>